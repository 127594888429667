import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
// import plus from "../../../assets/icons/a1.png";
// import colunms from "../../../assets/icons/LINES.png";
// import pen from "../../../assets/icons/pen.png";
// import basket from "../../../assets/icons/basket.png";
// import search1 from "../../../assets/icons/search.png";
// import top from "../../../assets/icons/top.png";
// import Table from "react-bootstrap/Table";
// import circle from "../../../assets/icons/circle.png";
// import rigth from "../../../assets/icons/rigth.png";
// import save from "../../../assets/icons/save.png";
// import cancel from "../../../assets/icons/cross.png";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import Header from "../../../Header/Header";
import { Context } from "../../../../utils/context";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fas } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Offcanvas from "react-bootstrap/Offcanvas";

import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { getAllOrderDetails } from "../../../../utils/apis/tiffin_orders/tiffin_subscription";

// ***************************************************************************************************************************************************************************
const EditOffCanvance = (props) => {
  const { postData, getData, RequiredIs, IMG_URL } = useContext(Context);

  const id = props.show;
  const [data, setData] = useState();

  const GetEditData = async () => {
    const response = await getData(`/employee/customers/details/${id}`);
    // const roleData = response.data.role;
    setData(response.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "100%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Customer Details</Card.Title>
              <hr />
              <h5>Address Details</h5>
              <table className="table table-responsive table-bordered">
                {/* <thead>
                  <tr>
                    <th>Address</th>
                  </tr>
                </thead> */}
                <tbody>
                  {data?.address?.map((feature, index) => (
                    <tr key={index}>
                      <td>
                        Address {index + 1}  :  {feature?.house}, {feature?.apartment}, {feature?.add_o}, {feature?.full_add_o}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <h5>Orders Details</h5>
              <table className="table table-responsive table-bordered">
                <thead>
                  {data?.orderStatusCount?.map((item) => (
                    <tr className="">
                      <td>{item?.tiffin_category?.name}</td>
                      <td>{item?.no}</td>
                    </tr>
                  ))}
                </thead>
              </table> */}
            
              {/* <table className="table table-responsive table-bordered">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Invoice Number</th>
                    <th>Payment Type</th>
                    <th>Order Type</th>
                    <th>Total</th>
                    <th>Discount</th>
                    <th>Delivery Charges</th>
                    <th>Dishes Count</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                 
                  {Object.keys(data?.orderStatusCount || {}).map((status) => (
                    <React.Fragment key={status}>
                    
                      {data.orderStatusCount[status].map((order, index) => (
                        <tr key={order.id}>
                       
                          {index === 0 && (
                            <td rowSpan={data.orderStatusCount[status].length}>
                              {status}
                            </td>
                          )}
                          <td>{order.invoice_number}</td>
                          <td>{order.payment_type}</td>
                          <td>{order.order_type}</td>
                          <td>{order.total}</td>
                          <td>{order.discount}</td>
                          <td>{order.delivery_charges}</td>
                          <td>{order.dishes_count}</td>
                          <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table> */}

              {data?.orderStatusCount && Object.keys(data.orderStatusCount).length > 0 && (
                <>
                  <h5>Orders Details</h5>
                  <table className="table table-responsive table-bordered">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Order Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Loop through each status in orderStatusCount */}
                      {Object.keys(data?.orderStatusCount || {}).map((status) => (
                        <tr key={status}>
                          <td>{status}</td>
                          <td>{data.orderStatusCount[status]?.length || 0}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}




              {data?.tiffinOrders && Object.keys(data.tiffinOrders).length > 0 && (
                <>
                  <h5>Tiffin Orders Details</h5>
                  <table className="table table-responsive table-bordered">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Order Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Loop through each status in tiffinOrders */}
                      {Object.keys(data.tiffinOrders).map((status) => (
                        <tr key={status}>
                          <td>{status}</td>
                          <td>{data.tiffinOrders[status]?.length || 0}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}


              {/* <table className="table table-responsive table-bordered">
                <thead>
                  <tr>
                    <th>Status</th>

                    <th>Order Type</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Day Type</th>
                    <th>Address ID</th>
                  </tr>
                </thead>
                <tbody>
                 
                  {Object.keys(data?.tiffinOrders || {}).map((status) => (
                    <React.Fragment key={status}>
                  
                      {data?.tiffinOrders[status]?.map((order, index) => (
                        <tr key={order.id}>
                         
                          {index === 0 && (
                            <td rowSpan={data?.tiffinOrders[status].length}>
                              {status}
                            </td>
                          )}

                          <td>{order.order_type}</td>
                          <td>{order.date}</td>
                          <td>{order.time}</td>
                          <td>{order.day_type?.name}</td>

                          <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table> */}

            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EditOffCanvance;
// *
