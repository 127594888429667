import React from "react";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Restourantordertable from "../../Orders/Orders/Orders";
import Dailytiffinorder from "../../TiffinOrders/TiffinDailyOrders/Orders";

const Orderlist = () => {
  return (
    <>
      <div className="restotiffi-divitab">
        <Tab.Container id="left-tabs-example" defaultActiveKey="Restaurent">
          <Nav variant="pills" className="">
            <Nav.Item>
              <Nav.Link eventKey="Restaurent">Restaurant Orders</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Tiffin">Tiffin Daily Orders</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="Restaurent">
              <Restourantordertable />
            </Tab.Pane>
            <Tab.Pane eventKey="Tiffin">
              <Dailytiffinorder />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default Orderlist;
