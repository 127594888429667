import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllOrders = async (
  seller_id,
  page = 1,
  search_name = "",
  startDate = "",
  endDate = "",
  orderSerachStatus=''
) => {
  try {
    return await getData(
      `/tiffin-orders/tiffin-subscription/all-orders?seller_id=${seller_id}&page=${page}&term=${search_name}&from=${startDate}&to=${endDate}&orderSerach=${orderSerachStatus}`
    );
  } catch (error) {
    console.error(error);
  }
};

export const getAllOrderDetails = async (id) => {
  try {
    return await getData(
      `/tiffin-orders/tiffin-subscription/all-orders-details/${id}`
    );
  } catch (error) {
    console.error(error);
  }
};

export const getAllOrderStatus = async () => {
  try {
    return await getData(`/tiffin-orders/tiffin-subscription/all-order-status`);
  } catch (error) {
    console.error(error);
  }
};

export const postAllOrderStatus = async (data, order) => {
  try {
    return await postData(
      `/tiffin-orders/tiffin-subscription/all-order-status`,
      {
        ids: data,
        order: order?.value,
      }
    );
  } catch (error) {
    console.error(error);
  }
};
