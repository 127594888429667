import React, { useState, useEffect, useContext } from "react";
// import "./b2b-table.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { motion } from "framer-motion";

import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from "react-router-dom";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import Pagination from "react-bootstrap/Pagination";
import { AnimatePresence } from "framer-motion";

// import Col from "react-bootstrap/Col";
// import Nav from "react-bootstrap/Nav";
// import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import { ShimmerTable } from "react-shimmer-effects";
// import { type } from "@testing-library/user-event/dist/type";
// import VeiwEditModal from "../../../../../../CommonForAll/VeiwEditModal/VeiwEditModal";
// import Mrp_modal from "../../../../../../CommonForAll/mrp-modal/Mrp_modal";
import CustomPaginate from "../../../common/CustomPaginate/CustomPaginate";
// import {
//   Category,
//   ProductStatus,
//   OrderStatus,
// } from "../../../../../../../utils/apis/master/Master";
// import {
//   getAllProducts,
//   productBTCStatusChange,
//   productStatusChange,
// } from "../../../../../../../utils/apis/seller/Product";
import { Context } from "../../../../../utils/context";
// import View_all_varients_offcanvas from "../../../../common-components/offcanvas/view-all-varients-offcanvas/View_all_varients_offcanvas";

import {
  getAllOrderStatus,
  getAllOrders,
  postAllOrderStatus,
  postAllDeliveryBoyAssign,
} from "../../../../../utils/apis/orders/Order";
import Select from "react-select";
import EditOffCanvance from "./Edit";
import { Button, Table } from "react-bootstrap";
import pen from "../../../assets/icons/pen.png";
import { IDS } from "../../../../../utils/common";
import Header from "../../../Header/Header";
import { DeliveryBoys } from "../../../../../utils/apis/masters/masters";
import { useLocation } from "react-router-dom";
const B2b_table = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { shimmerLoader, setShimmerLoader, Id, Select2Data } =
    useContext(Context);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [productId, setProductId] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [modalShowedit, setModalShowedit] = useState(false);
  const [varientsoffcanvas, setVarientsoffcanvas] = useState(false);
  const [productTitle, setProductTitle] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [category, setCategory] = useState("");
  const [varientType, setVarientType] = useState("");

  const [deleteshow, setdeleteShow] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const { IMG_URL, userdata, usertype } = useContext(Context);
  const [products, setProducts] = useState([]);

  const getOrders = async (page) => {
    const res = await getAllOrders(Id, page, productTitle, startDate, endDate,orderSerachStatus?.value);

    if (res?.success) {
      const newData = res?.data?.data;
      if (newData) {
        const newIds = newData.map((d) => d?.id);
        setAllChecked(newIds);
      }
      setProducts(res.data);
      // setShimmerLoader(false);
    }
  };

  const [orderStatus, setOrderStatus] = useState([]);
  const [orderSerachStatus, setOrderSearchStatus] = useState();
  const [orderChange, setOrderChange] = useState();

  const [DeliveryBoy, setDeliveryBoy] = useState([]);
  const [DeliveryBoyChange, setDeliveryBoyChange] = useState();

  const getMasters = async () => {
    {
      const res = await getAllOrderStatus();
      if (res?.success) {
        setOrderStatus(await Select2Data(res?.data, "order_status_id", false));
        // setOrderStatus(res?.data)
      }
    }
    {
      const res = await DeliveryBoys();
      if (res?.success) {
        const result = res?.data?.map((data) => ({
          value: data?.id,
          label: data?.f_name + " " + data?.l_name,
          name: "delivery_boy_id",
        }));
        setDeliveryBoy(result);
        // setOrderStatus(res?.data)
      }
    }
  };

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };
  const getChangeOrderStatus = async () => {
    {
      const res = await postAllOrderStatus(selectAllChecked, orderChange);
      if (res?.success) {
        setOrderChange("");
        setSelectAllChecked("");
        getOrders();
      }
    }
  };

  // const changeStatus = async () => {
  //   setShimmerLoader(true);

  //   if (selectAllChecked.length > 0) {
  //     for (const value of selectAllChecked) {
  //       await productStatusChange(value);
  //     }
  //   }

  //   setShimmerLoader(false);
  //   getOrders(currentPage);
  // };

  useEffect(() => {
    getChangeOrderStatus();
  }, [orderChange]);

  const getChangeDeliveryBoy = async () => {
    {
      const res = await postAllDeliveryBoyAssign(
        selectAllChecked,
        DeliveryBoyChange
      );
      if (res?.success) {
        setDeliveryBoyChange("");
        setSelectAllChecked("");
        getOrders();
      }
    }
  };

  useEffect(() => {
    getChangeDeliveryBoy();
  }, [DeliveryBoyChange]);

  useEffect(() => {
    getMasters();

    getOrders(currentPage);
  }, [currentPage, productTitle, searchStatus, category, startDate, endDate,orderSerachStatus]);

  const [show1, setShowEdit] = useState(0);
  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Header Handler
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  }, [location.pathname]);

  console.log(orderSerachStatus,"orderSerachStatus orderSerachStatus");
  
  return (
    <>
      <section className="B2b_table_section">
        {headerText.includes("/dashboard") ? (
          <></>
        ) : (
          <Header title={" Orders"} />
        )}
        <div className="p-3 mt-3">
          {headerText.includes("/dashboard") ? (
            <></>
          ) : (
            <>
              <AnimatePresence />
              <h6 className="pdlsis">Orders List</h6>
            </>
          )}
          <div className="product-main">
            <div className="search-main">
              <Form>
                <div className="row">
                  <div className="col-xxl-2 co-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <Form.Group className="form-group">
                      <Form.Label>Search</Form.Label>
                      <Form.Control
                        type="search"
                        placeholder="Search by User Name"
                        onChange={(e) => setProductTitle(e.target.value)}
                      />
                      <FontAwesomeIcon
                        className="serch-icoo"
                        icon="fa-solid fa-magnifying-glass"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xxl-2 co-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <Form.Group className="form-group">
                      <Form.Label>From</Form.Label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        name="start_date"
                        id="start_date"
                        autoComplete="off"
                        dateFormat="dd/MM/yyyy" // Set date format
                        className="form-control"
                        placeholderText="DD/MM/YYYY" // Update placeholder
                      />
                    </Form.Group>
                  </div>

                 
                  <div className="col-xxl-2 co-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="sec2">
                      <Form.Group className="form-group">
                        <Form.Label>To</Form.Label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          name="end_date"
                          id="end_date"
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy" // Set date format
                          className="form-control"
                          placeholderText="DD/MM/YYYY" // Update placeholder
                        />
                      </Form.Group>
                    </div>
                  </div>



                  {/* <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Categories</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setOrderChange(e.target.value)}
                    >
                      <option value="">-Please Select-</option>
                      {orderStatus?.map((value, index) => (
                        <option
                          value={value?.id}
                          selected={category == value?.id}
                        >
                          {value?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div> */}

                  {/* {usertype == IDS.usertype.Restaurent && ( */}
                  <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6">
                    <Form.Group className="form-group">
                      <Form.Label>Change Order Status</Form.Label>
                      <Select
                        value={orderChange}
                        onChange={(selectedOption) => {
                          if (selectAllChecked.length === 0) {
                            alert(
                              "Please Select At Least One Record To Order Change"
                            );
                          } else {
                            setOrderChange(selectedOption);
                          }
                        }}
                        options={orderStatus}
                        placeholder="-- select order --"
                        isSearchable
                      />
                    </Form.Group>
                  </div>


                  <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6">
                    <div className="num me-2">
                      <Form.Group className="form-group">
                        <Form.Label>Search Order Status</Form.Label>
                        <Select
                          value={orderSerachStatus}
                          onChange={(selectedOption) => {
                            setOrderSearchStatus(selectedOption);
                          }}
                          placeholder="-- select order --"
                          options={orderStatus}
                          isSearchable
                        />
                      </Form.Group>
                    </div>
                  </div>

                  {/* )} */}

                  <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3">
                    <Form.Group className="form-group">
                      <button
                        className="resetsearch-btn"
                        type="button"
                        onClick={() => {
                          setSearchStatus("");
                          setProductTitle("");
                          setStartDate("");
                          setEndDate("");
                          setCategory("");
                          setOrderStatus('');
                          setOrderSearchStatus('');
                        }}
                      >
                        Reset
                      </button>
                    </Form.Group>
                  </div>
                  {/* <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Sample Excel</Form.Label>
                    <div>
                      <img
                        className="excelimg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/dashboard//icons/excel.png"
                        }
                      />
                    </div>
                  </Form.Group>
                </div> */}
                  {/* <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3">
                  <Form.Group className="form-group">
                    <Form.Label>Download data in Excel</Form.Label>
                    <div>
                      <img
                        className="excelimg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/images/Seller_panel/dashboard//icons/excel.png"
                        }
                      />
                    </div>
                  </Form.Group>
                </div> */}
                </div>

                <div className="row mt-3 ">
                  <div className="col-xxl-2 co-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
                    <Form.Group className="form-group">
                      <Form.Label>Total Online</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={products?.total_online}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xxl-2 co-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
                    <Form.Group className="form-group">
                      <Form.Label>Total COD</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={products?.total_cod}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-xxl-3 co-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
                    <Form.Group className="form-group">
                      <Form.Label>Assign Delivery Boy</Form.Label>
                      <Select
                        value={orderChange}
                        onChange={(selectedOption) => {
                          if (selectAllChecked.length === 0) {
                            alert(
                              "Please Select At Least One Record To Assign Delivery Boy"
                            );
                          } else {
                            setDeliveryBoyChange(selectedOption);
                          }
                        }}
                        options={DeliveryBoy}
                        placeholder="-- Select Delivery Boy --"
                        isSearchable
                      />
                    </Form.Group>
                  </div>
                </div>


                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-4 col-md-3">
                    {/* <select
                    className="form-control"
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">-Please Select-</option>
                      {categories?.map((value, index) => (
                        <option
                          value={value?.id}
                          selected={category == value?.id}
                        >
                          {value?.name}
                        </option>
                      ))}
                    </select>

                  <button
                    className="status-change-btn"
                    type="button"
                    // onClick={changeStatus}
                  >
                    Status Change
                  </button> */}
                  </div>
                  {/* <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-9 col-6 text-end">
                    <div className="d-flex justify-content-end">
                      <div className="me-3">
                        <Form.Group className="form-group">
                          <div className="d-flex justify-content-end">
                            <div className="my-auto me-2">
                              <Form.Label>Search </Form.Label>
                            </div>
                            <div>
                              <Form.Control
                                type="search"
                                placeholder="Search here"
                              />
                              <FontAwesomeIcon
                                className="serch-icoo"
                                icon="fa-solid fa-magnifying-glass"
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="">
                        <Link to="/seller/seller-dashboard/sellerlistnewproduct">
                          <button className="listnewpro-btn">
                            <FontAwesomeIcon
                              className="me-2"
                              icon="fa-solid fa-plus"
                            />{" "}
                            List New Product
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div> */}
                </div>
              </Form>
            </div>

            {/* start tabs table section  */}

            <div className="productlisttabsssec">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="All_Products"
              >
                <Tab.Content>
                  <Tab.Pane eventKey="All_Products">
                    <Table bordered responsive>
                      <thead>
                        <tr className="">
                          <td className="tdd-checkkkk">
                            {/*  <input
                                type="checkbox"
                                value="selectAll"
                                checked={
                                  allChecked.length === selectAllChecked.length
                                }
                                onChange={handleChange}
                                id="selectAll"
                                className="me-1"
                              ></input>*/}
                          </td>
                          <td>Order Id </td>
                          <td>Invoice Number </td>
                          <td>Payment Mode </td>
                          <td>Payment ID </td>
                          <td>Total</td>
                          <td>User Name</td>
                          <td>Customer Id</td>
                          <td>Customer Contact Number</td>
                          <td>Discount Price</td>
                          {/* <td>MRP</td>
                          <td>Quantity</td> */}
                          <td>Order Status</td>
                          {/* <td>Payment Status</td> */}
                          <td>Delivery Boy</td>
                          <td>Created On</td>
                          <td>Updated On</td>
                          <td>View</td>
                          {/* <td>Total Orders</td> */}
                        </tr>
                      </thead>

                      <tbody>
                        {products?.data?.map((value, index) => (
                          <tr>
                            <td className="tdd-checkkkk">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                value={value?.id}
                                name="perselected"
                                checked={selectAllChecked.includes(value?.id)}
                                onChange={handleChange}
                                id="flexCheckDefault"
                              ></input>
                            </td>
                            <td> {value?.id}</td>
                            <td>{value?.invoice_number}</td>
                            <td>{value?.payment_type}</td>{" "}
                            <td>{value?.order_payment?.payment_id}</td>
                            <td>{value?.total}</td>{" "}
                            <td>{value?.address?.name}</td>{" "}
                            <td>{value?.user_id}</td>{" "}
                            <td>{value?.user?.contact_no}</td>{" "}
                            <td>{value?.discount}</td>{" "}
                            {/* <td>
                              {value?.o_w_orders_details?.map((data, index) => {
                                return data.mrp;
                              })}
                            </td>{" "}
                            <td>
                              {value?.o_w_orders_details?.map((data, index) => {
                                return data.quantity;
                              })}
                            </td>{" "} */}
                            <td>{value?.order_status?.name}</td>{" "}
                            {/* <td>{value?.o_w_payment_status?.name}</td>{" "} */}
                            <td>
                              {value?.order_assigned &&
                                value?.order_assigned?.user?.f_name +
                                " " +
                                value?.order_assigned?.user?.l_name}
                            </td>
                            <td>
                              {value?.createdAt &&
                                new Date(value.createdAt).toLocaleString(
                                  "en-US",
                                  {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                            </td>
                            <td>
                              {value?.updatedAt &&
                                new Date(value.updatedAt).toLocaleString(
                                  "en-US",
                                  {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                            </td>
                            <td>
                              <div className="d-flex">
                                <Button
                                  onClick={() => handleShow1(value?.id)}
                                  type="button"
                                  className="btn btn-primary me-1"
                                >
                                  <img src={pen} className="pen" alt="" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <div className="pagination-div">
                      <CustomPaginate
                        currentPage={currentPage}
                        totalPages={products?.totalPages}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>

            {/* <VeiwEditModal
            show={modalShowedit}
            getAllProducts={getAllProducts}
            varientType={varientType}
            productId={productId}
            type={type}
            onHide={() => setModalShowedit(false)}
          /> */}

            {/* <Mrp_modal show={modalShow} onHide={() => setModalShow(false)} /> */}
          </div>
          {show1 ? (
            <EditOffCanvance
              handleClose={handleClose1}
              setShow={setShowEdit}
              show={show1}
            />
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
};

export default B2b_table;
